<template>
    <div class="container mb-3">
        <div class="row">
            <div class="col-md-6">
                <h3>City Master </h3>
            </div>
            <div class="col-md-6">
                <!-- <pre>{{ form }} {{ query }}</pre> -->
                <button class="btn btn-primary " v-if="!seen" @click="seen = !seen" style="float: right;" id="btnAdd">
                    <i class="fa fa-plus-circle" aria-hidden="true"></i> Add City</button>
                <button class="btn btn-primary" v-if="seen" @click="seen = !seen" style="float: right;" id="btnHide">
                    <i class="fa fa-minus-circle" aria-hidden="true"></i>
                    Hide</button>
            </div>
        </div>
        <div class="row" v-if="seen">
            <div class="col">

                <!-- <pre>{{ form }}</pre> -->
                <!-- <pre>{{ stateId }}</pre> -->
                <!-- <pre>{{ cityId }}</pre> -->
                <form @submit="onSubmit" class="city-from city-mas">
                    <div class="row">
                   <div class="col-md-4 mb-3">
                    <label for="countryId" class="form-label">Countery * </label>
                            <select v-model="countryId" id="countryId" @change="getStatesData()" class="form-select" aria-label="Default select example">
                                <option selected Disabled>Select Country</option>
                                <option v-for="country in countries" :key="country.id" :value="{ id: country.id, text: country.countryName }">{{
                                    country.countryName
                                }}</option>
                            </select>   
                    </div>
                   <div class="col-md-4 mb-3" v-if="countries">
                    <label for="stateId" class="form-label">States * </label>
                            <select v-model="stateId" id="stateId" @change="getCitiesData()" class="form-select" aria-label="Default select example">
                                <option selected Disabled>Select State</option>
                                <option v-for="state in states" :key="state.id" :value="{ id: state.id, text: state.stateName }">{{
                                    state.stateName
                                }}</option>
                            </select>   
                    </div>
                   <div class="col-md-4 mb-3">
                    <label for="cityId" class="form-label">City * </label>
                            <select v-model="cityId" id="cityId"  class="form-select" aria-label="Default select example">
                                <option selected Disabled>Select City</option>
                                <option v-for="city in citiesDD" :key="city.id" :value="{ id: city.id, text: city.cityName }">{{
                                    city.cityName
                                }}</option>
                            </select>   
                    </div>
                    <div class="invalid-feedback">
                        Please provide a valid State.
                    </div>

                    <div class="btn-bottom">   
                        <input type="submit" :value="btnText" class="btn  btn-primary" />
                    </div>

                </div>                    
                </form>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="table-responsive">
            <table class="table table-hover table-bordered" ref="cityTable">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>City Name</th>
                        <th>State Name</th>
                        <th>Country Name</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="city in cities" :key="city.id">
                        <td>{{ city.id }}</td>
                        <td>{{ city.cityName }}</td>
                        <td>{{ city.stateName }}</td>
                        <td>{{ city.countryName }}</td>

                         <td>
                            <!-- <button class="btn btn-success btn-sm" @click="editdata(city.id)"> <i class="fa fa-pencil"
                                    aria-hidden="true"></i> Edit</button> -->
                                    <button class="btn btn-danger btn-sm"  
                                @click="deletedata(city.id)"><i class="fa fa-trash" aria-hidden="true"></i>
                                Delete</button> </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="container" v-if="loading">
        <div class="row">
            <div class="col">
                <Spinner />
            </div>
        </div>
    </div>

</template>

<script>
import { Country } from '../services/Country';
import { State } from '../services/State';
import { City } from '../services/City'
import Spinner from '../components/Spinner.vue';
import $ from "jquery";
import dt from "datatables.net";
$(function () {
    //  alert('ok');

});

export default {
    name: 'CityMaster',
    components: {
        Spinner
    },
    data() {
        return {
            form: {
                id: '',
                cityName: '',
                stateName: '',
                countryName: '',
            },
            seen: false,
            loading: false, 
            countryId : '',
            stateId :'',
            cityId : '',
            states: [],
            countries: [],            
            cities: [],
            citiesDD: [],
            errors: [],
            btnText: 'Save',
            errorMasage: null,
            dt: dt
        }
    },
    mounted() {
        this.dt = $(this.$refs.cityTable).DataTable(
            // {
            //     destroy: true,
            //     processing: true,
            //     select: true,
            //     paging: true,
            //     lengthChange: true,
            //     searching: true,
            //     info: false,
            //     responsive: true,
            //     autoWidth: false
            // }
        );
    }
    , watch: {
        cities() {
            this.dt.destroy();
            this.$nextTick(() => {
                this.dt = $(this.$refs.cityTable).DataTable()
            })
        }
    },
    async created() {
        try {
            this.loading = true
            let response = await Country.getCountries()
            this.countries = response.data;
            this.loading = false
        }
        catch (error) {
            this.errorMasage = error;
            this.loading = false
        }
       
        try {
            this.loading = true
            let response = await City.getCities()
            this.cities = response.data;
            this.loading = false
        }
        catch (error) {
            this.errorMasage = error;
            this.loading = false
        }

    }, async updated() {

    }
    , methods: {
        async onSubmit(e) {
            e.preventDefault()
            this.form.cityName = this.cityId.text;
            this.form.stateName = this.stateId.text;
            this.form.countryName =  this.countryId.text;
            if (!this.form.countryName) {
                alert('Please Enter Country name')
                return
            }
            if (!this.form.stateName) {
                alert('Please Enter State name')
                return
            }
            if (!this.form.cityName) {
                alert('Please Enter City name')
                return
            }



            // const response = await Country.createCountry(form);
            if (this.btnText === 'Save') {
                try {
                    const response = await City.createCity(this.form);
                    // console.log(response);
                    if (response.data.status == 'success') {
                        alert('created');
                    } else {
                        alert('error');
                    }

                    this.form.stateName = "";
                    this.form.cityName = "";
                    this.form.countryName = "";
                } catch (error) {
                    // console.log("this is error ", error)
                    if (error.response.status === 422) {
                        console.log(error.response.data.errors.name);

                    }
                    // console.log(errors.value.name);
                }
            } else {
                // alert('update');
                try {
                    const response = await City.updateCity(this.form, this.form.id);
                    // console.log(response);
                    if (response.data.status == 'success') {
                        // alert('Updated');
                    } else {
                        alert('error');
                    }
                    this.form.stateName = "";
                    this.form.cityName = "";
                    this.form.countryName = "";
                } catch (error) {
                    // console.log("this is error ", error)
                    if (error.response.status === 422) {
                        console.log(error.response.data.errors.name);

                    }
                    // console.log(errors.value.name);
                }
                this.btnText = "Save";
            }
            // this.loading = true
            // let response = await State.getStates()
            // console.log(response.data);
            // this.states = response.data.data;
            this.loading = false
            location.reload();

        },
        async editdata(id) {
            // alert(id);
            this.btnText = "Update";
            const response = await City.getCity(id);
            console.log(response);
            this.form.id = id;
            // this.countryId.text = response.data.countryName;
            this.form.cityName = response.data.cityName;
            this.form.stateName = response.data.stateName;
            this.form.countryName = response.data.countryName;
            console.log(response.data.name);
            this.seen = true;
            // this.btnText = "Save";
        },
        async deletedata(id) {
            alert(id);
            const resDel = await City.deleteCity(id)
            console.log(resDel);
            this.loading = true;
            let response = await City.getCities()
            this.cities = response.data.data;
            this.loading = false;
        },
        async getStatesData(){
        try {
            this.stateId =""
            this.cityId =""
            // alert('changed' + this.countryId);
            // const form ={
            //     "countryId" : this.countryId
            // }
            this.loading = true;
            let response = await State.getStateData(this.countryId.id);
            this.states = response.data;
            this.loading = false
        }
        catch (error) {
            this.errorMasage = error;
            this.loading = false
        }
    },
        async getCitiesData(){
        try {
            this.cityId =""
            // alert('changed' + this.stateId.id);
            // const form ={
            //     "countryId" : this.countryId
            // }
            this.loading = true;
            let response = await City.getCityData(this.stateId.id);
            this.citiesDD = response.data;
            this.loading = false
        }
        catch (error) {
            this.errorMasage = error;
            this.loading = false
        }
    }
        // async getStates() {
        //     alert('changed' + this.form.countryId);
        //     // alert('update');
        //     try {
        //         this.loading = true
        //         let response = await State.getStates()
        //         let options = response.data.data;
        //         this.states = options.reduce(function (filtered, option) {
        //             // alert(option.countryId);
        //             if (option.countryId == this.form.countryId) {
        //                 var someNewValue = { name: option.name, id: option.id }
        //                 filtered.push(someNewValue);
        //             }
        //             return filtered;
        //         }, []);
        //         // alert(reduced);
        //         // console.log(reduced);
        //         this.states = response.data.data;
        //         this.loading = false
        //     }
        //     catch (error) {
        //         this.errorMasage = error;
        //         this.loading = false
        //     }
        // }
    },
   
}
</script>
<style scoped>
.city-mas {
    background: #fbfbfb;
    border: solid 1px #d2d2d2;
    padding: 30px;
    margin: 20px 0px;
}
.city-mas .form-control {
    padding: 12px 10px;
    border-radius: 0;
 
}
.multiselect {
    border-radius: 0;
    padding: 4px 10px;
}
.btn-bottom {
    text-align: center;
}
.city-mas .btn.btn-primary {
    border-radius: 3px;
    padding: 10px 25px;
    display: inline-block;
    font-size: 18px;
    font-weight: 700;
}
.city-mas .form-select {
    padding: 12px 10px;
    border-radius: 0;
}
.table > thead {
    vertical-align: bottom;
    background: lightskyblue;
}
tbody tr td {
    border-right: 1px solid #a7daf9;
}
tbody tr td:first-child {
    border-left: 1px solid #a7daf9;
}


tr.odd  { background-color:#FFF;vertical-align: middle; }
tr.even { background-color:#e5f5ff;vertical-align: middle; }
</style>